<template>
  <div style="display: table; height: 100%; width: 100%">
    <div
      style="display: table-cell; vertical-align: middle; text-align: center"
    >
      <template v-for="item in routes">
        <div v-if="item.name && item.meta" :key="item.name">
          <router-link :to="{ name: item.name }">{{
            item.meta.name
          }}</router-link>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { routes } from "@/router";
export default {
  name: "PageViewer",
  data() {
    return { routes };
  },
  created() {},
};
</script>
